import { useFormik } from "formik";
import { InputLabel, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { clearErrors, login as userLogin } from "../redux/actions/user";
import { useEffect } from "react";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import { permissionActions } from "../redux/slice/permissionSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import * as yup from "yup";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  //   const {
  //     isLoading,
  //     loginWithPopup,
  //     loginWithRedirect,
  //     error,
  //     user,
  //     logout,
  //     isAuthenticated
  //   } = useAuth();

  // const isLogin = localStorage.getItem("token");

  const { user, error } = useSelector((state) => state.login);
  const { fleetList, error: fleetError } = useSelector(
    (state) => state.fleetList
  );

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(5, "Password should be of minimum 5 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      showPassword: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(userLogin(values.email, values.password));
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const notifyfialed = () => toast.error("Invalid Credentials!");

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (user && Object.keys(user).length > 0) {
      localStorage.setItem("useremail", user.data.emailId);
      localStorage.setItem("token", user.data.token);
      localStorage.setItem("role", user.data.role);
      localStorage.setItem("firstname", user.data.firstName);
      localStorage.setItem("lastname", user.data.lastName);
      dispatch(fleetListAndRobotStatusByUserEmail(user.data.emailId));
      // toast.success("Logged in Successfully!");
      // else {
      //   toast.error("No Fleet is assigned to this user!");
      // }
    }

    if (fleetList && fleetList.length > 0) {
      // notifySuccess();
      localStorage.setItem("fleetData", JSON.stringify(fleetList[0]));
      navigate(`/fleetPage/${fleetList[0].fleetName}`, {
        state: { fleetData: fleetList[0],
        userData: true },
      });
    } 
    // else if(fleetList && fleetList.length === 0) {
    //   navigate(`/dashboard`, {
    //     state: { fleetData: {} },
    //   });
    //   // toast.error("No fleet assigned to this user.")
    // }

    if (fleetError) {
      // localStorage.setItem("fleetData", JSON.stringify(fleetList[0]));
      console.error(fleetError);
      // dispatch(clearErrors());
      navigate(`/dashboard`);
    }

    // if (
    //   localStorage.getItem("token") &&
    //   localStorage.getItem("token") !== "null"
    // ) {
    //   const fleetData = localStorage.getItem("fleetData");
    //   const emailId = localStorage.getItem("useremail");
    //   navigate(`/fleetPage/${fleetData[0].fleetName}`, {
    //     state: { email: emailId, fleetData: fleetData[0] },
    //   });
    // }
  }, [navigate, error, dispatch, user, fleetList, fleetError]);

  return (
    <div className="DesktopMain-wrapper">
      <div className="Login_page-wholeMain-wrapper">
        <div className="LoginPage-Main-wrapper">
          <img
            src="/assets/images/OttonomyLogo.png"
            className="Login-page-form-logo-image"
            alt="logo"
          />
          <form onSubmit={formik.handleSubmit} className="Login-page-form">
            <div className="Loginpage=-main-form">
              <div className="Login_page_input_wholeLabel_wrapper">
                <InputLabel
                  style={{
                    textAlign: "left",
                    color: "#fff",
                    marginBottom: "8px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Email Id
                </InputLabel>
                <div className="Loginpage-main-form-inner-div">
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{}}
                    value={formik.values.email}
                    name="email"
                    onChange={formik.handleChange}
                    placeholder="Email ID"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
              </div>
              <div className="Login_page_input_wholeLabel_wrapper">
                <InputLabel
                  style={{
                    textAlign: "left",
                    color: "#fff",
                    marginBottom: "8px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Password
                </InputLabel>
                <div className="Loginpage-main-form-inner-div">
                  <TextField
                    sx={{ borderRadius: "0px", width: "100%" }}
                    fullWidth
                    id="password"
                    variant="standard"
                    name="password"
                    placeholder="Password"
                    type={formik.values.showPassword ? "password" : "text"}
                    InputProps={{
                      // startAdornment: (
                      // <InputAdornment position="start">
                      // <LockIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                      // </InputAdornment>
                      // ),
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            formik.setFieldValue(
                              "showPassword",
                              !formik.values.showPassword
                            );
                            // console.log('showPassword',formik.values.showPassword)
                          }}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          style={{
                            color: "rgb(189,196,224)",
                            marginRight: "2px",
                          }}
                        >
                          {formik.values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      ),
                    }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </div>
              </div>
            </div>
            <div className="Loginpage-btn-wrapper">
              <button type="submit" className="Loginpage-btn-wrapper-btn">
                {" "}
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
