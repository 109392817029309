import axios from "axios";
import { ImCancelCircle } from "react-icons/im";
import { MdBolt } from "react-icons/md";
// import { FaExclamation } from "react-icons/fa6";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHasPermissionInAnyFleet } from "../customHooks/PermissionHooks";
import ConfirmationModel from "./ConfirmationModel";
import Loader from "../components/Reusable/Loader";



export default function RobotCard({
  index,
  robot,
  selectedLocation,
  showSummonControls,
  playSound,
  stopSound,
}) {
  const [borderColorClass, setBorderColorClass] = useState(
    "border-robotStatusYellow"
  );
  const [loading, setLoading] = useState(false);
  const [robotStatusText, setRobotStatusText] = useState("");
  const [shouldPlayAudio, setShouldPlayAudio] = useState(false);
  const [modalText, setModalText] = useState("");
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleOpenModal = (message, confirmCallback) => {
    setModalText(message);
    setConfirmAction(() => confirmCallback);
    setShowConfirmationModal(true);
  };
  useEffect(() => {
    if (playSound && typeof playSound === "function" && shouldPlayAudio) {
      playSound();
    }
    setShouldPlayAudio(false);
  }, [shouldPlayAudio, playSound]);
  useEffect(() => {
    function getRobotStatusText() {
      if (robot?.teleop === "YES") {
        setShouldPlayAudio(true);
        setBorderColorClass("border-robotStatusRed");
        return (
          <>
            <span className="text-red-500">Stuck in traffic </span>
            <span>
              {robot?.nearestLocation ? "near " + robot?.nearestLocation : ""}
            </span>
          </>
        );
      } else if (robot?.robotStatus === "ERROR") {
        setShouldPlayAudio(true);
        setBorderColorClass("border-robotStatusRed");
        return (
          <>
            <span className="text-robotStatusRed">In ERROR </span>
            <span>
              {robot?.nearestLocation ? "near " + robot?.nearestLocation : ""}
            </span>
          </>
        );
      } else if (robot?.robotRunningState === "ESTOP_PRESSED") {
        setShouldPlayAudio(true);
        setBorderColorClass("border-robotStatusRed");
        return (
          <>
            <span className="text-robotStatusRed">Emergency stop pressed </span>
            <span>
              {robot?.nearestLocation ? "near " + robot?.nearestLocation : ""}
            </span>
          </>
        );
      } else if (robot?.robotBatteryStatus !== "OK") {
        setShouldPlayAudio(true);
        setBorderColorClass("border-robotStatusRed");
        return (
          <>
            <span className="text-robotStatusRed">Low Battery </span>
            <span>
              {robot?.nearestLocation ? "near " + robot?.nearestLocation : ""}
            </span>
          </>
        );
      } else if (robot?.robotStatus === "CHARGING") {
        setBorderColorClass("border-[#71cf62]");

        return (
          <>
            <span className="text-[#71cf62]">Charging </span>
            <span>
              {robot?.nearestLocation ? "at " + robot?.nearestLocation : ""}
            </span>
          </>
        );
      } else if (robot?.robotStatus === "AVAILABLE") {
        if (!robot?.currentRobotAction) {
          setBorderColorClass("border-robotStatusYellow");
          return (
            <>
              <span>Available </span>
              <span>
                {robot?.nearestLocation ? "near " + robot?.nearestLocation : ""}
              </span>
            </>
          );
        } else if (robot?.currentRobotAction?.actionPriority === 0) {
          setBorderColorClass("border-robotStatusYellow");
          return (
            <>
              <span>Ready to be dispatched </span>
              <span>
                {robot?.nearestLocation ? "near " + robot?.nearestLocation : ""}
              </span>
            </>
          );
        } else if (robot?.currentRobotAction?.actionPriority === 1) {
          if (robot?.currentRobotAction?.message === "MOVELOAD") {
            setBorderColorClass("border-robotStatusYellow");
            return (
              <>
                <span>Reaching </span>
                <span>
                  {robot?.currentRobotAction?.locationName
                    ? robot?.currentRobotAction?.locationName + " for pickup"
                    : " pickup"}
                </span>
                {robot?.ETA && robot?.ETA !== 0 ? (
                  <>
                    <span> in </span>
                    <span className="text-[#71cf62] font-bold">
                      {Math.ceil(robot.ETA / 60)}
                    </span>
                    <span> Mins</span>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          } else if (robot?.currentRobotAction?.message === "EXPECTLOAD") {
            setBorderColorClass("border-robotStatusYellow");
            return (
              <>
                <span>Waiting pickup </span>
                <span>
                  {robot?.currentRobotAction?.locationName
                    ? "at " + robot?.currentRobotAction?.locationName
                    : ""}
                </span>
              </>
            );
          } else if (robot?.currentRobotAction?.message === "MOVEUNLOAD") {
            setBorderColorClass("border-robotStatusYellow");
            return (
              <>
                <span>Reaching </span>
                <span>
                  {robot?.currentRobotAction?.locationName
                    ? robot?.currentRobotAction?.locationName + " for dropoff"
                    : " dropoff"}
                </span>
                {robot?.ETA && robot?.ETA !== 0 ? (
                  <>
                    <span> in </span>
                    <span className="text-[#71cf62] font-bold">
                      {Math.ceil(robot.ETA / 60)}
                    </span>
                    <span> Mins</span>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          } else if (robot?.currentRobotAction?.message === "EXPECTUNLOAD") {
            setBorderColorClass("border-robotStatusYellow");
            return (
              <>
                <span>Waiting dropoff </span>
                <span>
                  {robot?.currentRobotAction?.locationName
                    ? "at " + robot?.currentRobotAction?.locationName
                    : ""}
                </span>
              </>
            );
          } else if (robot?.currentRobotAction?.message === "MOVEWANDER") {
            setBorderColorClass("border-robotStatusYellow");
            return (
              <>
                <span>
                  {robot?.currentRobotAction?.locationName
                    ? "Reaching " + robot?.currentRobotAction?.locationName
                    : "Wandering "}
                </span>
                {robot?.ETA && robot?.ETA !== 0 ? (
                  <>
                    <span> in </span>
                    <span className="text-[#71cf62] font-bold">
                      {Math.ceil(robot.ETA / 60)}
                    </span>
                    <span> Mins</span>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          } else if (robot?.currentRobotAction?.message === "MOVEHOME") {
            setBorderColorClass("border-robotStatusYellow");
            return (
              <>
                <span>
                  {robot?.currentRobotAction?.locationName
                    ? "Reaching " + robot?.currentRobotAction?.locationName
                    : "Wandering "}
                </span>
                {robot?.ETA && robot?.ETA !== 0 ? (
                  <>
                    <span> in </span>
                    <span className="text-[#71cf62] font-bold">
                      {Math.ceil(robot.ETA / 60)}
                    </span>
                    <span> Mins</span>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          }
          else if (robot?.currentRobotAction?.message === "MOVESUMMON") {
            setBorderColorClass("border-robotStatusYellow");
            return (
              <>
                <span>
                  {robot?.currentRobotAction?.locationName
                    ? "Reaching " + robot?.currentRobotAction?.locationName
                    : "Wandering "}
                </span>
                {robot?.ETA && robot?.ETA !== 0 ? (
                  <>
                    <span> in </span>
                    <span className="text-[#71cf62] font-bold">
                      {Math.ceil(robot.ETA / 60)}
                    </span>
                    <span> Mins</span>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          }
        }
      } else if (robot?.robotStatus === "UNAVAILABLE") {
        setBorderColorClass("border-robotStatusYellow");
        return (
          <>
            <span>Unavailable </span>
            <span>
              {robot?.nearestLocation ? "near " + robot?.nearestLocation : ""}
            </span>
          </>
        );
      } else {
        setBorderColorClass("border-robotStatusYellow");
      }
    }
    setRobotStatusText(getRobotStatusText());
  }, [robot]);

  // {robot?.robotStatus === "AVAILABLE" &&
  //   robot?.teleop === "NO" &&
  //   robot?.currentRobotAction &&
  //   robot?.currentRobotAction?.message === "MOVESUMMON" &&
  //   robot?.currentRobotAction?.actionPriority === 1 && (
  //     <>
  //       {`Reaching `}
  //       {robot?.currentRobotAction?.locationName
  //         ? robot?.currentRobotAction?.locationName
  //         : ""}
  //       {robot?.ETA && robot?.ETA !== 0
  //         ? ` in ${Math.ceil(robot.ETA / 60)} Mins`
  //         : ""}
  //       {showSummonControls && (
  //         <div
  //           className="flex items-center gap-1 border-b border-[#E96C67] w-fit cursor-pointer"
  //           onClick={() =>
  //             cancelCurrentAction(
  //               robot?.currentRobotAction?.actionId,
  //               robot?.robotId
  //             )
  //           }
  //         >
  //           <ImCancelCircle className="w-3 h-3 cursor-pointer text-[#E96C67]" />
  //           <span className="text-[10px] font-semibold text-[#E96C67]">
  //             Cancel Summon
  //           </span>
  //         </div>
  //       )}
  //     </>
  //   )}
  // {robot?.teleop === "NO" &&
  //   robot?.commandQueue?.some(
  //     (command) =>
  //       command?.message === "MOVESUMMON" &&
  //       command?.locationName === selectedLocation?.label
  //   ) && (
  //     <>
  //       {`Reaching `}
  //       {robot?.currentRobotAction?.locationName
  //         ? robot?.currentRobotAction?.locationName
  //         : ""}
  //       {robot?.ETA && robot?.ETA !== 0
  //         ? ` in ${Math.ceil(robot.ETA / 60)} Mins`
  //         : ""}
  //       {showSummonControls && (
  //         <div
  //           className="flex items-center gap-1 border-b border-[#E96C67] w-fit cursor-pointer"
  //           onClick={() =>
  //             cancelCurrentAction(
  //               robot?.currentRobotAction?.actionId,
  //               robot?.robotId
  //             )
  //           }
  //         >
  //           <ImCancelCircle className="w-3 h-3 cursor-pointer text-[#E96C67]" />
  //           <span className="text-[10px] font-semibold text-[#E96C67]">
  //             Cancel Summon
  //           </span>
  //         </div>
  //       )}
  //     </>
  //   )}
  // {robot?.robotStatus === "AVAILABLE" &&
  //   robot?.teleop === "NO" &&
  //   robot?.currentRobotAction &&
  //   robot?.currentRobotAction?.actionPriority === 1 &&
  //   robot?.currentRobotAction?.message === "MOVEHOME" && (
  //     <>
  //       {`Reaching `}
  //       {robot?.currentRobotAction?.locationName
  //         ? ` ${robot?.currentRobotAction?.locationName}${
  //             robot?.ETA && robot?.ETA !== 0
  //               ? ` in ${Math.ceil(robot.ETA / 60)} Mins`
  //               : ""
  //           }`
  //         : ""}
  //   </>
  // )}
  // }
  const token = localStorage.getItem("token");
  const hasPermissionForCancelActions =
    useHasPermissionInAnyFleet("cancelActions");
  const cancelCurrentAction = async (actionId, robotId) => {
    setLoading(true);
    try {
      const cancelAction = await axios.post(
        `${process.env.REACT_APP_BASE_URL}robot/cancelActionByID`,
        {
          robotId,
          actionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if(cancelAction.status===200){
        setLoading(false);
        toast.success(cancelAction.data.message);
      }
      else{
        setLoading(false);
        toast.error("something went wrong");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  const getrobotIcons = (item) => {
    return (
      <div className="flex items-center">
        {/* <img src="/assets/images/CPUred.svg" alt="CPU_icon" /> */}
        {/* <FaExclamation
          className={`${
            item.robotBatteryStatus === "OK" ? "hidden" : ""
          } text-robotStatusRed`}
        /> */}
        <MdBolt
          className={`${
            item.robotStatus !== "CHARGING" ? "hidden" : ""
          } text-[#71cf62]`}
        />
        <div className="battery-icon-css flex">
          <span
            style={{
              width: "30px",
              border: "1px solid #fff",
              borderRadius: "4px",
              padding: "2px",
              height: "14px",
              display: "inline-block",
            }}
          >
            <strong
              style={{
                background:
                  item.robotBatteryStatus !== "OK" ? "#E4261C" : "#71cf62",
                width: `${
                  parseFloat(item.robotBattery) < 0
                    ? 0
                    : parseFloat(item.robotBattery)
                }%`,
                display: "block",
                height: "8px",
              }}
            ></strong>
          </span>
        </div>
        <p
          style={{
            textWrap: "nowrap",
            fontSize: "10px",
            textAlign: "center",
            fontWeight: "bold",
            color: "#FFFFFF",
          }}
        >
          {parseFloat(item.robotBattery).toFixed(0)} %
        </p>
        {/* <img src="/assets/images/Bagred.svg" alt="bagred_icon" /> */}
      </div>
    );
  };
  return (
    <>
    {loading && <Loader />}
    <div
      key={index}
      className={`OttobotCard w-full px-4 py-4 rounded-md border-[1px] ${borderColorClass} rounded-md justify-between inline-flex min-w-[310px]`}
    >
      <div className="flex-col w-full justify-start items-start inline-flex">
        <div className="flex justify-between w-full">
          <div className="text-white text-base font-bold ">
            {robot?.robotId}
          </div>
          {(hasPermissionForCancelActions && robot?.robotStatus === "AVAILABLE"&&robot?.currentRobotAction?.actionPriority === 1&&robot?.currentRobotAction?.message === "MOVEHOME")&& (
                  <div
                    className="w-[200px] h-[38px] px-[34px] py-2.5 bg-red-500 rounded-[7px] justify-center items-center gap-2 inline-flex cursor-pointer"
                    onClick={() =>handleOpenModal("Are you sure you want to cancel home",()=>cancelCurrentAction(robot?.currentRobotAction?.actionId,robot?.robotId))}
                  >
                    <ImCancelCircle className="text-white" />
                    <span className="text-center text-white text-xs font-bold font-['Poppins']">
                      Cancel Home
                    </span>
                  </div>
                )}
                {(showSummonControls&&robot?.robotStatus === "AVAILABLE"&&robot?.currentRobotAction?.actionPriority === 1&&robot?.currentRobotAction?.message === "MOVESUMMON")&& (
                  <div
                    className="w-[200px] h-[38px] px-[34px] py-2.5 bg-red-500 rounded-[7px] justify-center items-center gap-2 inline-flex cursor-pointer"
                    onClick={() =>handleOpenModal("Are you sure you want to cancel the summon",()=>cancelCurrentAction(robot?.currentRobotAction?.actionId,robot?.robotId))}
                  >
                    <ImCancelCircle className="text-white" />
                    <span className="text-center text-white text-xs font-bold font-['Poppins']">
                      Cancel Summon
                    </span>
                  </div>
                )}
                  {(showSummonControls&&robot?.robotStatus === "AVAILABLE"&&robot?.commandQueue?.some((command)=>(command.message==="MOVESUMMON"&&command.locationName===selectedLocation.label&&command.actionPriority!==1)))&& (
                  <div
                    className="w-[200px] h-[38px] px-[34px] py-2.5 bg-red-500 rounded-[7px] justify-center items-center gap-2 inline-flex cursor-pointer"
                    onClick={() =>handleOpenModal("Are you sure you want to cancel the summon",()=>cancelCurrentAction(robot?.commandQueue?.find((command)=>(command.message==="MOVESUMMON"&&command.locationName===selectedLocation.label)).actionId,robot?.robotId))}
                  >
                    <ImCancelCircle className="text-white" />
                    <span className="text-center text-white text-xs font-bold font-['Poppins']">
                      Cancel request
                    </span>
                  </div>
                )}
          {getrobotIcons(robot)}
        </div>
        <div className="self-stretch text-stone-300 text-sm font-normal md:text-wrap ">
          {robotStatusText}
        </div>
      </div>

      {/* <div className="flex flex-col gap-1 items-end"> */}
      {/* {robot?.robotStatus === "AVAILABLE" &&
                      robot?.ETA &&
                      robot?.ETA !== 0 ? (
                        <div className="flex gap-2 items-center items-baseline">
                          <div className="text-white text-sm font-normal">
                            ETA :
                          </div>
                          <div className="flex justify-end gap-1 items-baseline">
                            <div className="text-cyan-500 text-sm font-bold ">
                              {Math.ceil(robot.ETA / 60)}
                            </div>
                            <div className="text-cyan-500 text-sm font-normal">
                              Mins
                            </div>
                          </div>
                        </div>
                        
                      ) : null} */}
      {/* </div> */}

    </div>
    <ConfirmationModel
        textToDisplay={modalText}
        onConfirm={confirmAction}
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
      />
      </>
  );
}
