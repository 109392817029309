import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StackedBarChart = ({ analyticsData, dataKey }) => {
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.color = "white";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b) => b.lines);
      const div = document.createElement("div");

      div.className = "flex flex-col items-center justify-center";

      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];

        let numberData = body[i].split(":");

        const span1 = document.createElement("span");
        span1.style.color = colors.backgroundColor;
        span1.className = "text-xl font-semibold leading-5";

        const span2 = document.createElement("span");
        span2.style.color = "rgba(2, 2, 2, 0.5)";
        span2.className = "text-[11px]";

        const text1 = document.createTextNode(numberData[1]);
        const text2 = document.createTextNode(tooltip.title[0]);

        span1.appendChild(text1);
        div.appendChild(span1);

        span2.appendChild(text2);
        div.appendChild(span2);
      });

      const tableRoot = tooltipEl.querySelector("table");

      while (tooltipEl?.firstChild) {
        tooltipEl.firstChild.remove();
      }

      tooltipEl?.appendChild(div);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.width = "max-content";
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
    tooltipEl.style.background = "white";
    tooltipEl.style.borderRadius = "5px";
  };

  const options = {
    indexAxis: "x",
    elements: {
      // bar: {
      //   borderRadius: 5,
      // },
    },

    plugins: {
      legend: {
        display: true,
        labels: {
          pointStyle: "rectRounded",
          useBorderRadius: true,
          // boxWidth: 20,
          font: {
            size: 12,
            weight: "500",
            family: "Poppins",
          },
          color: "#C1C2C3",
        },
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#C1C2C3",
        },
        line: "#C1C2C3",
        borderColor: "#C1C2C3",
        grid: {
          display: false,
          borderColor: "#C1C2C3",
          drawOnChartArea: false,
          color: "#C1C2C3",
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#C1C2C3",
        },
        borderColor: "#C1C2C3",
        line: "#C1C2C3",
        grid: {
          display: false,
          borderColor: "#C1C2C3",
          drawOnChartArea: false,
          color: "#C1C2C3",
        },
      },
    },
  };

  const calculateDayOfWeek = (dayNumber) => {
    switch (dayNumber) {
      case "1":
        return "Monday";

      case "2":
        return "Tuesday";

      case "3":
        return "Wednesday";

      case "4":
        return "Thursday";

      case "5":
        return "Friday";

      case "6":
        return "Saturday";

      default:
        return "Sunday";
    }
  };

  const convertTo12HourFormat = (hours) => {
    if (parseInt(hours) === 0) {
      return "12 AM";
    } else if (parseInt(hours) === 12) {
      return "12 PM";
    } else if (parseInt(hours) < 12) {
      return hours + " AM";
    } else {
      return hours - 12 + " PM";
    }
  };

  const calculateMonthOfYear = (monthValue) => {
    switch (monthValue) {
      case "01":
        return "January";

      case "02":
        return "February";

      case "03":
        return "March";

      case "04":
        return "April";

      case "05":
        return "May";

      case "06":
        return "June";

      case "07":
        return "July";

      case "08":
        return "August";

      case "09":
        return "September";

      case "10":
        return "October";

      case "11":
        return "November";

      case "12":
        return "December";

      default:
        return "January";
    }
  }

  // const labels = ["January", "February", "March", "April", "May", "June", "July"];

  const labels = analyticsData[dataKey]
    .sort((a, b) => a._id - b._id)
    .map((data, index) => {
      if (dataKey === "dayOfWeek") {
        return calculateDayOfWeek(data._id);
      } else if (dataKey === "hourOfDay") {
        return convertTo12HourFormat(data._id);
      } else if (dataKey === "monthOfYear") {
        return calculateMonthOfYear(data._id)
      }
      return null;
    });

  const deliveredData = analyticsData[dataKey]
    .sort((a, b) => a._id - b._id)
    .map((day, index) => {
      return day.completed;
    });

  const cancelledData = analyticsData[dataKey]
    .sort((a, b) => a._id - b._id)
    .map((day, index) => {
      return day.cancelled;
    });

  const data = {
    labels,
    datasets: [
      {
        label: `${!!Number(process.env.REACT_APP_TRIP)?"Trips":"Orders"} Cancelled`,
        data: cancelledData,
        backgroundColor: "#ED6D85",
        borderRadius: 5,
      },
      {
        label: `${!!Number(process.env.REACT_APP_TRIP)?"Trips Completed":"Orders Delivered"}`,
        data: deliveredData,
        backgroundColor: "#6DBEBF",
        borderRadius: 5,
      },
    ],
  };

  return (
    <>
      {/* {analyticsData &&
      analyticsData.hasOwnProperty("stacked") &&
      analyticsData.stacked.length > 0 ? ( */}
      <Bar options={options} data={data} />
      {/* ) : (
        <span className="flex items-center justify-center w-full text-lg font-semibold text-gray-400">
         {" "}
          No data to show
        </span>
       )} */}
    </>
  );
};

export default StackedBarChart;
