import { Autocomplete, FormControl, Switch, TextField } from "@mui/material";
import { useState} from "react";
import { useFormik } from "formik";
import {
  addLocation,
  editLocation,
  askuploadtype,
  updateimagetoCdn,
} from "../API";
import { toast } from "react-toastify";
import * as yup from "yup";
import Loader from "./Loader";
import { MdCloudUpload, MdDelete } from "react-icons/md";

const EditLocationForm = ({
  locationDetails,
  setEditLocationModalOpen,
  setLoader,
  fetchLocationsByType,
  setLocationsList,
  updateLocationGroupIds,
  groupTypeOptions
}) => {
  const [locationTypeOptions, setLocationTypeOptions] = useState([
    "DROPOFF",
    "HOME",
    "PICKUP",
    "RELOCALIZATION",
    "WANDER"
  ]);
  // const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [imageAsset, setImageAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  const token = localStorage.getItem("token");

  const uploadImage = (e) => {
    // console.log("UPLOAD IMAGE EVENT", e);
    let file = e.target.files[0];
    setFile(file);
    // console.log(file, "FILE");
    const reader = new FileReader();
    let url = reader.readAsDataURL(file);
    // console.log(url);

    reader.onloadend = function (e) {
      setImageAsset(reader.result);
      // console.log("RESULT", reader.result);
    };
  };

  const validationSchema = yup.object({
    locationName: yup.string(),
    locationType: yup
      .string()
      .typeError("Location Type must be string.")
      .oneOf(locationTypeOptions)
      .required("Location Type is required."),
    latitude: yup.string(),
    longitude: yup.string(),
    headingAngle: yup.string(),
    positionX: yup.string(),
    positionY: yup.string(),
    positionYaw: yup.string(),
    status: yup.boolean(),
    imageLocationUrl: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      locationName: locationDetails?.locationName
        ? locationDetails.locationName
        : "",
      locationType: locationDetails?.locationType
        ? locationDetails.locationType
        : "",
      groupType : locationDetails?.group ? locationDetails?.group : "", 
      group : "",
      latitude: locationDetails?.latitude ? locationDetails.latitude : "",
      longitude: locationDetails?.longitude ? locationDetails.longitude : "",
      headingAngle: locationDetails?.headingAngle
        ? locationDetails.headingAngle
        : "",
      positionX: locationDetails?.positionX ? locationDetails.positionX : "",
      positionY: locationDetails?.positionY ? locationDetails.positionY : "",
      positionYaw: locationDetails?.positionYaw
        ? locationDetails.positionYaw
        : "",
      status: locationDetails?.status ? locationDetails.status : true,
      imageLocationUrl: locationDetails?.imageLocationUrl
        ? locationDetails.imageLocationUrl
        : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if(values?.groupType !== "Custom"){
        values.group = values.groupType;
      }
      //   console.log(values, "FORM VALUES");
      if (imageAsset) {
        // let formData = new FormData();
        // formData.append("file", file);
        // console.log("FILE Before", file)
        let bodyFormData = new FormData();

        askuploadtype(file.type, token)
          .then((result) => {
            bodyFormData.append("file", file);
            // console.log(result.data.data.uploadURL, "ASDK RESULT");
            updateimagetoCdn(result.data.data.uploadURL, bodyFormData, token)
              .then((res) => {
                // console.log(res.data.result);
                values.imageLocationUrl = res.data.result.variants[0];
                editLocation(locationDetails._id, values)
                  .then((res) => {
                    setLoader(false);
                    setEditLocationModalOpen(false);
                    toast.success("Edited Location successfully!");
                    resetForm({ values: "" });
                    fetchLocationsByType()
                      .then((result) => {
                        if (result.status === 200) {
                          setLocationsList(result?.data?.data);
                          updateLocationGroupIds(result?.data?.data)
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  })
                  .catch((err) => {
                    setLoader(false);
                    setEditLocationModalOpen(false);
                    console.error(err);
                    toast.error(err.message);
                    // toast.error("Something went wrong while editing location!");
                  });
                setLoader(true);
              })
              .catch((err) => {
                console.log("Error in Image to CDN Upload", err);
              });
          })
          .catch((err) => {
            console.log("Error in Ask Image Upload", err);
          });
        setLoader(true);
        // imageuploadv2(token, imageAsset)
        //   .then((res) => {
        //     console.log("Image Upload response", res);
        //     formik.setFieldValue("imageLocationUrl", res.data.path);
        //   })
        //   .catch((err) => {
        //     console.log("Error in Image Upload", err);
        //   });
      } else {
        editLocation(locationDetails._id, values)
          .then((res) => {
            setLoader(false);
            setEditLocationModalOpen(false);
            toast.success("Edited Location successfully!");
            resetForm({ values: "" });
            fetchLocationsByType()
              .then((result) => {
                if (result.status === 200) {
                  setLocationsList(result?.data?.data);
                  updateLocationGroupIds(result?.data?.data)
                }
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((err) => {
            setLoader(false);
            setEditLocationModalOpen(false);
            console.error(err);
            toast.error(err.message);
            // toast.error("Something went wrong while editing location!");
          });
      }

      setLoader(true);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="AddFleet_form_field_wrapper addlocation_textfield form__control__wrapper flex-wrap gap-4 px-2 sm:px-5 max-h-[calc(100vh-250px)] sm:max-h-[250px] overflow-scroll">
        <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
          <label className="!ml-0 font-semibold">Location Name</label>
          <TextField
            id="location-name"
            fullWidth
            value={formik.values.locationName}
            name="locationName"
            onChange={formik.handleChange}
            error={
              formik.touched.locationName && Boolean(formik.errors.locationName)
            }
            helperText={
              formik.touched.locationName && formik.errors.locationName
            }
          />
        </div>

        <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
          <label className="!ml-0 font-semibold">Select Location Type</label>
          <FormControl className="bg-[rgb(28,30,43)] text-[rgb(189,196,224)]">
            <Autocomplete
              options={locationTypeOptions}
              helpertext={
                formik.touched.locationType && formik.errors.locationType
              }
              value={
                formik.values.locationType === ""
                  ? null
                  : locationTypeOptions.find((option) => {
                      return option === formik.values.locationType;
                    }) || null
              }
              onChange={(_, selectedOption) => {
                formik.setFieldValue("locationType", selectedOption);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="text-[rgb(189,196,224)] !m-0 !p-[5px_5px_5px_10px] text-sm"
                />
              )}
            />
          </FormControl>
          <div>
            {" "}
            {formik.errors.locationType && formik.touched.locationType ? (
              <p className="text-[#d32f2f] text-xs -mt-[2px] text-left !pl-0">
                Location Type is req.
              </p>
            ) : null}
          </div>
        </div>
        <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                    <label className="!ml-0 font-semibold">
                      Select Group Type
                    </label>
                    <FormControl className="bg-[rgb(28,30,43)] text-[rgb(189,196,224)]">
                      <Autocomplete
                        options={groupTypeOptions}
                        helpertext={
                          formik.touched.groupType &&
                          formik.errors.groupType
                        }
                        value={
                          formik.values.groupType === ""
                            ? null
                            : groupTypeOptions.find((option) => {
                                return option === formik.values.groupType;
                              }) || null
                        }
                        onChange={(_, selectedOption) => {
                          formik.setFieldValue("groupType", selectedOption);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className="text-[rgb(189,196,224)] !m-0 !p-[5px_5px_5px_10px] text-sm"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  {formik.values.groupType === "Custom" ? <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
                    <label className="!ml-0 font-semibold">Group</label>
                    <TextField
                      id="location-group"
                      fullWidth
                      value={formik.values.group}
                      name="group"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.group &&
                        Boolean(formik.errors.group)
                      }
                      helperText={
                        formik.touched.group && formik.errors.group
                      }
                    />
                  </div>: null}
        <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
          <label className="!ml-0 font-semibold">Latitude</label>
          <TextField
            id="location-latitude"
            fullWidth
            value={formik.values.latitude}
            name="latitude"
            onChange={formik.handleChange}
            error={formik.touched.latitude && Boolean(formik.errors.latitude)}
            helperText={formik.touched.latitude && formik.errors.latitude}
          />
        </div>

        <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
          <label className="!ml-0 font-semibold">Longitude</label>
          <TextField
            id="location-longitude"
            fullWidth
            value={formik.values.longitude}
            name="longitude"
            onChange={formik.handleChange}
            error={formik.touched.longitude && Boolean(formik.errors.longitude)}
            helperText={formik.touched.longitude && formik.errors.longitude}
          />
        </div>

        <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
          <label className="!ml-0 font-semibold">Heading Angle</label>
          <TextField
            id="location-heading-angle"
            fullWidth
            value={formik.values.headingAngle}
            name="headingAngle"
            onChange={formik.handleChange}
            error={
              formik.touched.headingAngle && Boolean(formik.errors.headingAngle)
            }
            helperText={
              formik.touched.headingAngle && formik.errors.headingAngle
            }
          />
        </div>

        <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
          <label className="!ml-0 font-semibold">Position X</label>
          <TextField
            id="location-position-x"
            fullWidth
            value={formik.values.positionX}
            name="positionX"
            onChange={formik.handleChange}
            error={formik.touched.positionX && Boolean(formik.errors.positionX)}
            helperText={formik.touched.positionX && formik.errors.positionX}
          />
        </div>

        <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
          <label className="!ml-0 font-semibold">Position Y</label>
          <TextField
            id="location-position-y"
            fullWidth
            value={formik.values.positionY}
            name="positionY"
            onChange={formik.handleChange}
            error={formik.touched.positionY && Boolean(formik.errors.positionY)}
            helperText={formik.touched.positionY && formik.errors.positionY}
          />
        </div>

        <div className="w-[100%] sm:w-[30%] flex flex-col gap-1">
          <label className="!ml-0 font-semibold">Position Yaw</label>
          <TextField
            id="location-position-yaw"
            fullWidth
            value={formik.values.positionYaw}
            name="positionYaw"
            onChange={formik.handleChange}
            error={
              formik.touched.positionYaw && Boolean(formik.errors.positionYaw)
            }
            helperText={formik.touched.positionYaw && formik.errors.positionYaw}
          />
        </div>

        <div className="w-[100%] sm:w-[30%] flex items-center gap-1">
          <label className="!ml-0 font-semibold">Status</label>
          <Switch
            id="location-status"
            name="status"
            checked={formik.values.status}
            style={{
              color: "#FFF",
            }}
            onChange={formik.handleChange}
            error={formik.touched.status && Boolean(formik.errors.status)}
            helpertext={formik.touched.status && formik.errors.status}
          />
        </div>

        <div className="w-[100%] flex flex-col items-start justify-center ml-3 gap-1">
          <label className="!ml-0 font-semibold">Image</label>
          <div className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer group">
            <>
              {!imageAsset && formik.values.imageLocationUrl === "" ? (
                <>
                  <label className="flex flex-col items-center justify-center w-full h-full cursor-pointer min-h-[150px]">
                    <div className="flex flex-col items-center justify-center w-full h-full">
                      <MdCloudUpload className="text-3xl text-gray-500 hover:!text-ottonomyBlue" />
                      <p className="text-gray-500 hover:text-ottonomyBlue">
                        Click here to upload
                      </p>
                    </div>
                    <input
                      type="file"
                      name="uploadImage"
                      aceept="image/*"
                      onChange={uploadImage}
                      className="hidden"
                    />
                  </label>
                </>
              ) : (
                <>
                  <div className="relative h-full p-3">
                    <img
                      src={
                        imageAsset ? imageAsset : formik.values.imageLocationUrl !== "" ? formik.values.imageLocationUrl : imageAsset
                      }
                      alt="uploadedImage"
                      className="object-cover w-full h-full"
                    />
                    <button
                      type="button"
                      className="absolute p-2 text-xl transition-all duration-500 ease-in-out bg-red-500 rounded-full outline-none cursor-pointer bottom-2 right-2 hover:shadow-md"
                      onClick={() => {
                        setImageAsset(null);
                        if (formik.values.imageLocationUrl !== "") {
                          formik.setFieldValue("imageLocationUrl", "");
                        }
                        console.log(formik.values.imageLocationUrl, "IMAGE URL")
                        setFile(null);
                      }}
                    >
                      <MdDelete className="!text-white fill-white" />
                    </button>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>

      <div className="absolute inset-x-0 bottom-0 flex justify-between px-3 Order_modal_button_wrapper sm:relative">
        <button
          onClick={() => setEditLocationModalOpen(false)}
          className="!p-[10px_20px] m-[20px] font-bold text-white transition-all border-2 rounded-lg bg-ottonomyDarkBlue border-ottonomyBlue hover:bg-ottonomyBlue"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="transition-all Order_save_button hover:bg-ottonomyBlue"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default EditLocationForm;
