import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Button, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import OTPVerification from "./components/OTPVerification";
import IndividualFleet from "./subcomponents/IndividualFleet";
import RobotIndividual from "./subcomponents/RobotIndividual";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import IndividualUsers from "./subcomponents/IndividualUsers";
import LogOutModal from "./subcomponents/LogOutModal";
import Dashboard from "./components/Dashboard";
import RobotPage from "./subcomponents/RobotPage";
import EditFleet from "./subcomponents/EditFleet";
import FleetPage from "./subcomponents/FleetPage";
import OrderList from "./subcomponents/OrderList";
import EditRobot from "./subcomponents/EditRobot";
import EditUser from "./subcomponents/EditUser";
import AddOrder from "./subcomponents/AddOrder";
import UserList from "./subcomponents/UserList";
import AddFleet from "./subcomponents/AddFleet";
import AddRobot from "./subcomponents/AddRobot";
import AddUser from "./subcomponents/AddUser";
import Login from "./components/Login";
import Locations from "./components/Map/Locations";
import UserManagement from "./components/UserManagment";
import OrdersReports from "./subcomponents/OrdersReports";
import Unauthorized from "./components/Unauthorized";
import { fetchUserPermissions } from "./redux/slice/permissionSlice";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import "./App.css";

function App() {
  const dashboardopen = true;
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const currentlocation = useLocation();
  const firstName = localStorage.getItem("firstname");
  const lastName = localStorage.getItem("lastname");
  const emailId = localStorage.getItem("useremail");
  const role = localStorage.getItem("role");
  const [currentcomponent, setcurrentcomponent] = useState("");
  const { userPermission }  = useSelector((state) => state.permissionReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!userPermission?.length){
    dispatch(fetchUserPermissions());
    }
  }, [dispatch,userPermission]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const navigate = useNavigate();
  useEffect(() => {
    setcurrentcomponent(currentlocation.pathname);
  }, [currentlocation.pathname]);

  return (
    <div className="App">
      <div className="Hamburger_icon_wrapper">
        <div>
          <img
            style={{ width: "auto", height: "33px" }}
            src="/assets/images/OttonomyLogo.png"
            className="logo-btm"
          />
        </div>
        {["right"].map((anchor) => (
          <Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>
              <MenuIcon />
            </Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              sx={{ color: "#282F42" }}
              id="Responsive_drawer"
            >
              <ul className={"Dashboard_page_side_bar"}>
                <Link
                  to="/dashboard"
                  style={{ textDecoration: "none" }}
                  className="Dashboard_side_bar_links"
                  onClick={() => {
                    setcurrentcomponent("/dashboard");
                    navigate("/dashboard");
                    setState({ ...state, [anchor]: false });
                  }}
                >
                  <li
                    className={
                      currentcomponent === "/dashboard"
                        ? "SideBar_active"
                        : null
                    }
                  >
                    <span></span>
                    <p
                      style={{ marginBottom: "0px" }}
                      className="Dashboard_side_bar_P"
                    >
                      Dashboard
                    </p>
                  </li>
                </Link>
                <Link
                  to="/fleetPage"
                  style={{ textDecoration: "none" }}
                  className="Dashboard_side_bar_links"
                  onClick={() => {
                    setcurrentcomponent("/fleetpage");
                    navigate("/fleetpage");
                    setState({ ...state, [anchor]: false });
                  }}
                >
                  <li
                    className={
                      currentcomponent.startsWith("/fleetPage") ||
                      currentcomponent === "/fleetpage"
                        ? "SideBar_active"
                        : null
                    }
                  >
                    <span style={{ width: "25%" }}></span>
                    <p
                      style={{ marginBottom: "0px" }}
                      className="Dashboard_side_bar_P"
                    >
                      Fleets
                    </p>
                  </li>
                </Link>
                <Link
                  to="/robotpage"
                  style={{ textDecoration: "none" }}
                  className="Dashboard_side_bar_links"
                  onClick={() => {
                    setcurrentcomponent("/robotpage");
                    navigate("/robotpage");
                    setState({ ...state, [anchor]: false });
                  }}
                >
                  <li
                    className={
                      currentcomponent === "/robotpage"
                        ? "SideBar_active"
                        : null
                    }
                  >
                    <span style={{ width: "25%" }}></span>
                    <p
                      style={{ marginBottom: "0px" }}
                      className="Dashboard_side_bar_P"
                    >
                      Robots
                    </p>
                  </li>
                </Link>
                  <Link
                    to="/locations"
                    style={{ textDecoration: "none" }}
                    className="Dashboard_side_bar_links"
                    onClick={() => {
                      setcurrentcomponent("/locations");
                      navigate("/locations");
                      setState({ ...state, [anchor]: false });
                    }}
                  >
                    <li
                      className={
                        currentcomponent === "/locations"
                          ? "SideBar_active"
                          : null
                      }
                    >
                      <span style={{ width: "25%" }}></span>
                      <p
                        style={{ marginBottom: "0px" }}
                        className="Dashboard_side_bar_P"
                      >
                        Locations
                      </p>
                    </li>
                  </Link>
                <Link
                  to="/analytics"
                  style={{ textDecoration: "none" }}
                  className="Dashboard_side_bar_links"
                  onClick={() => {
                    setcurrentcomponent("/analytics");
                    navigate("/analytics");
                    setState({ ...state, [anchor]: false });
                  }}
                >
                  <li
                    className={
                      currentcomponent === "/analytics"
                        ? "SideBar_active"
                        : null
                    }
                  >
                    <span style={{ width: "25%" }}></span>
                    <p
                      style={{ marginBottom: "0px" }}
                      className="Dashboard_side_bar_P"
                    >
                      Analytics
                    </p>
                  </li>
                </Link>
              </ul>
              <div className={"name-log_two"}>
                <div>
                  <p className="SideBar_name_p">
                    {firstName !== "undefined" || lastName !== "undefined" ? (
                      <>
                        {firstName}
                        {lastName}
                      </>
                    ) : (
                      <>{emailId}</>
                    )}
                  </p>
                </div>
                <LogOutModal />
              </div>
            </Drawer>
          </Fragment>
        ))}
      </div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={<Dashboard dashboardopen={dashboardopen} />}
        />
       
        <Route
          path="/robotpage"
          element={<RobotPage dashboardopen={dashboardopen} />}
        />
          <Route
            path="/locations"
            element={
              // <ProtectedRoute requiredPermission="locations">
                <Locations dashboardopen={dashboardopen} />
              // </ProtectedRoute>
            }
          />
        <Route
          path="/analytics"
          element={
            // <ProtectedRoute requiredPermission="fleet-analytics">
              <OrdersReports dashboardopen={dashboardopen} />
            // </ProtectedRoute> 
          }
        />
         <Route
          path="/fleetPage"
          element={
            // <ProtectedRoute requiredPermission="fleet-view">
              <FleetPage dashboardopen={dashboardopen} />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            // <ProtectedRoute requiredPermission="user-management">
              <UserManagement dashboardopen={dashboardopen} />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/addfleet"
          element={
            // <ProtectedRoute requiredPermission="fleet-management">
              <AddFleet dashboardopen={dashboardopen} />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/addrobot"
          element={
            // <ProtectedRoute requiredPermission="robot-management">
              <AddRobot dashboardopen={dashboardopen} />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/adduser"
          element={
            // <ProtectedRoute requiredPermission="user-management">
              <AddUser dashboardopen={dashboardopen} />
            // </ProtectedRoute>
          }
        />
        <Route
          path="robotPage/:robotId"
          element={
            // <ProtectedRoute requiredPermission="robot-management">
              <RobotIndividual dashboardopen={dashboardopen} />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/userlist"
          element={<UserList dashboardopen={dashboardopen} />}
        />
        <Route
          path="/addorder"
          element={<AddOrder dashboardopen={dashboardopen} />}
        />
         <Route
          path="/editfleet"
          element={<EditFleet dashboardopen={dashboardopen} />}
        />
        <Route
          path="/editfleet/:fleetId"
          element={<EditFleet dashboardopen={dashboardopen} />}
        />
        <Route
          path="/editrobot"
          element={<EditRobot dashboardopen={dashboardopen} />}
        />
        <Route
          path="/edituser"
          element={<EditUser dashboardopen={dashboardopen} />}
        />
        <Route
          path="/otpverification"
          element={<OTPVerification dashboardopen={dashboardopen} />}
        />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route
          path="/orderlist"
          element={<OrderList dashboardopen={dashboardopen} />}
        />
        <Route
          path="fleetPage/:fleetId"
          element={<IndividualFleet dashboardopen={dashboardopen} />}
        />
        
        
        <Route
          path="editrobot/:robotId"
          element={<EditRobot dashboardopen={dashboardopen} />}
        />
        <Route
          path="userlist/:userId"
          element={<IndividualUsers dashboardopen={dashboardopen} />}
        />
        <Route
          path="edituser/:userid"
          element={<EditUser dashboardopen={dashboardopen} />}
        />
         <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </div>
  );
}

export default App;
