import axios from "axios";
import { userConstants } from "../constants/user";
import { setUserPermission, emptyUserPermission } from "../slice/permissionSlice";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: userConstants.LOGIN_REQUEST,
    });

    const data = await axios.post(
      `${process.env.REACT_APP_BASE_URL}login-user`,
      {
        emailId: email,
        password: password,
      }
    );
    if (data.status === "200" || data.status === 200) {
      const { role } = data?.data?.data
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        // payload: data.user,
        payload: data.data,
      });
      dispatch(setUserPermission(role))
    } else throw data.data.message;
  } catch (error) {
    dispatch({
      type: userConstants.LOGIN_FAIL,
      payload: error,
    });
  }
};

// Load user
// export const loadUser = () => async (dispatch) => {
//     try {
//       dispatch({
//         type: userConstants.LOAD_USER_REQUEST,
//       });

//       const { data } = await axios.get(`${server}/profile`);

//       dispatch({
//         type: userConstants.LOAD_USER_SUCCESS,
//         payload: data.user,
//       });
//     } catch (error) {
//       dispatch({
//         type: userConstants.LOAD_USER_FAIL,
//         payload: error.response.data.error,
//       });
//     }
//   };

// Logout user
export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: userConstants.LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({
      type: userConstants.LOGOUT_FAIL,
      payload: error.response.data.error,
    });
  }
};

export const destroySessionUser = () => (dispatch) => {
  try {
    dispatch({
      type: userConstants.DESTROY_SESSION,
    });
  } catch (error) {
    // dispatch({
    //   type: robotConstants.ROBOT_DETAILS_FAIL,
    //   payload: error,
    // });
  }
};

// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: userConstants.CLEAR_ERRORS,
  });
};

export const sidebarcollpase = (sidebarcollpse) => async (dispatch) => {
  dispatch({
    type: userConstants.SIDEBAR_COLLAPSE,
    payload: sidebarcollpse,
  });
};
